/**
* THIS FILE CONTAINS GLOBAL CSS DECLARATIONS
* import 'App.css' to use it (relative path)
*/
body {
  background-color: "rgb(250, 250, 250)";
}

body, html {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
}

.capital {
  text-transform: capitalize;
}

.margin-left-small {
  margin-left: 10px;
}

.word-break {
  word-break: keep-all;
}
